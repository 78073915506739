<template>
  <div class="contents dashboard" v-loading="loading">
    <div class="title flexB">
      <h2>대시보드</h2>
    </div>
    <div class="box one filter">
      <div class="buttonWrap refresh">
        <p>{{ moment().format("YYYY.MM.DD HH:mm") }}</p>
        <!-- <button class="basic" @click="getCount" :class="{ loading: inactive == true }">새로고침 <img src="@/assets/images/refresh.svg" alt="새로고침" /></button> -->
        <el-button class="basic" @click="getCount" :loading="inactive">새로고침</el-button>
      </div>
      <div class="contBox">
        <h2>가입자 정보</h2>
        <div class="contWrap first flex">
          <div class="cont">
            <h4><span class="material-icons"> account_circle </span> 전체 가입자</h4>
            <h3>{{ numberFormat(totalUser) }}<span>명</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> account_circle </span> 일별 가입자</h4>
            <h3>{{ numberFormat(dayJoinUser) }}<span>명</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> account_circle </span> 월별 가입자</h4>
            <h3>{{ numberFormat(monthJoinUser) }}<span>명</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> account_circle </span> 탈퇴 회원</h4>
            <h3>{{ numberFormat(dropUser) }}<span>명</span></h3>
          </div>
        </div>
      </div>
      <div class="contBox">
        <h2>농장 정보</h2>
        <div class="contWrap second flex">
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 전체 등록된 농장</h4>
            <h3>{{ numberFormat(totalFarmCount) }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 이번달 등록된 농장</h4>
            <h3>{{ numberFormat(monthFarmCount) }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 신규 등록된 농장</h4>
            <h3>{{ numberFormat(todayFarmCount) }}<span>개</span></h3>
          </div>
        </div>
      </div>
      <div class="contBox">
        <h2>생육데이터 정보</h2>
        <div class="contWrap second flex">
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 전체 등록된 생육데이터 정보</h4>
            <h3>{{ numberFormat(totalGrowthCount) }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 이번달 등록된 생육데이터 정보</h4>
            <h3>{{ numberFormat(monthGrowthCount) }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 신규 등록된 생육데이터 정보</h4>
            <h3>{{ numberFormat(todayGrowthCount) }}<span>개</span></h3>
          </div>
        </div>
      </div>
      <div class="contBox">
        <h2>디바이스 정보</h2>
        <div class="contWrap second flex">
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 전체 등록된 디바이스</h4>
            <h3>{{ numberFormat(totalDeviceCount) }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 이번달 등록된 디바이스</h4>
            <h3>{{ numberFormat(monthDeviceCount) }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 신규 등록된 디바이스</h4>
            <h3>{{ numberFormat(todayDeviceCount) }}<span>개</span></h3>
          </div>
        </div>
      </div>
      <div class="contBox">
        <h2>영농일지 정보</h2>
        <div class="contWrap second flex">
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 전체 등록된 영농일지</h4>
            <h3>{{ numberFormat(totalDiaryCount) }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 이번달 등록된 영농일지</h4>
            <h3>{{ numberFormat(monthDiaryCount) }}<span>개</span></h3>
          </div>
          <div class="cont">
            <h4><span class="material-icons"> foundation </span> 신규 등록된 영농일지</h4>
            <h3>{{ numberFormat(todayDiaryCount) }}<span>개</span></h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { format } from "@/mixins/format";
import { fetchDashCount } from "@/api/admin";

export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      totalUser: 0,
      dayJoinUser: 0,
      monthJoinUser: 0,
      dropUser: 0,
      totalFarmCount: 0,
      monthFarmCount: 0,
      todayFarmCount: 0,

      totalGrowthCount: 0,
      monthGrowthCount: 0,
      todayGrowthCount: 0,

      totalDeviceCount: 0,
      monthDeviceCount: 0,
      todayDeviceCount: 0,

      totalDiaryCount: 0,
      monthDiaryCount: 0,
      todayDiaryCount: 0,
      loading: false,
      inactive: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "0");
  },
  mounted() {
    this.loading = true;
    this.getCount();
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getCount() {
      this.inactive = true;
      fetchDashCount().then((res) => {
        if (res.data.status == 200) {
          this.totalUser = res.data.totalUser;
          this.dayJoinUser = res.data.dayJoinUser;
          this.monthJoinUser = res.data.thisMonthJoinUser;
          this.dropUser = res.data.dropUser;
          this.totalFarmCount = res.data.totalFarmCount;
          this.monthFarmCount = res.data.monthFarmCount;
          this.todayFarmCount = res.data.todayFarmCount;

          this.totalGrowthCount = res.data.totalGrowthCount;
          this.monthGrowthCount = res.data.monthGrowthCount;
          this.todayGrowthCount = res.data.todayGrowthCount;

          this.totalDeviceCount = res.data.totalDeviceCount;
          this.monthDeviceCount = res.data.monthDeviceCount;
          this.todayDeviceCount = res.data.todayDeviceCount;

          this.totalDiaryCount = res.data.totalDiaryCount;
          this.monthDiaryCount = res.data.monthDiaryCount;
          this.todayDiaryCount = res.data.todayDiaryCount;
        } else {
          this.openAlert(res.data.message);
        }
        this.loading = false;
        this.inactive = false;
      });
    },
  },
};
</script>
